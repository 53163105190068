/*
 "Lookup definition" is a proof-of-concept feature.
 It is only intended for Lex and not other tenants.
*/

(function definitions() {
  // Disable on viewports narrower than 1280px.
  if (
    Math.max(document.documentElement.clientWidth, window.innerWidth) < 1280
  ) {
    return;
  }

  var rootContainerSelector = ".lookup-definitions__container";
  var initialStateRootContainer = document.querySelector(rootContainerSelector);
  if (!initialStateRootContainer) {
    console.log("Container element not found");
    return;
  }

  function getSelectedWord() {
    var selection = window.getSelection();
    if (!selection || selection.rangeCount < 1) {
      return null;
    }

    var selectionRange = selection.getRangeAt(0).toString().trim();
    if (selectionRange.indexOf(" ") > -1) {
      return null;
    }

    return selectionRange;
  }

  function setChildInnerTextBySelector(
    container,
    selector,
    text,
    shouldAppend,
  ) {
    var childNodes = container.querySelectorAll(selector);
    if (childNodes.length) {
      var prefix = shouldAppend
        ? childNodes[childNodes.length - 1].innerText
        : "";
      childNodes[childNodes.length - 1].innerText = prefix + text;
    }
  }

  function createDdoSearchUrl(query, urlSuffix) {
    return "https://ordnet.dk/ddo/ordbog?query=" + query + (urlSuffix || "");
  }

  function setTitleAnchorHref(container, selector, item, initialSelectedWord) {
    var titleAnchors = container.querySelectorAll(selector);
    if (!titleAnchors.length) {
      return;
    }

    var select = "";
    if (item.option > 1 || item.word !== initialSelectedWord) {
      select = ["&select=" + item.word, item.option].filter(Boolean).join(",");
    }
    titleAnchors[titleAnchors.length - 1].href = createDdoSearchUrl(
      initialSelectedWord,
      select,
    );
  }

  function createDdoLink(word) {
    if (!word) {
      return;
    }

    var anchor = document.createElement("a");
    anchor.appendChild(document.createTextNode(word));
    anchor.href = createDdoSearchUrl(word);
    anchor.target = "_blank";
    return anchor;
  }

  function createLabeledElement(labelClass, text) {
    if (!text || !text.length) {
      return;
    }

    var elementContainer = document.createElement("div");

    var usageLabel = document.createElement("span");
    usageLabel.className = "lookup-definitions__label-box " + labelClass;
    elementContainer.appendChild(usageLabel);

    var usageTextElement = document.createElement("span");
    if (typeof text === "string") {
      usageTextElement.innerText = text;
    } else {
      if (Array.isArray(text)) {
        text.forEach(function (element) {
          usageTextElement.appendChild(element);
        });
      } else {
        usageTextElement.appendChild(text);
      }
    }

    elementContainer.appendChild(usageTextElement);
    return elementContainer;
  }

  function createSubDefinitionsList(subDefinitions, elementToAppendTo) {
    if (Array.isArray(subDefinitions) && subDefinitions.length) {
      var list = document.createElement("ol");
      list.className = "lookup-definitions__definitions__subdefinitions";

      subDefinitions.forEach(function (subDefinition) {
        var item = document.createElement("li");
        if (subDefinition.domain) {
          item.appendChild(createDomainElement(subDefinition.domain));
        }

        [subDefinition.definition, subDefinition.usage]
          .filter(Boolean)
          .forEach(function (field) {
            item.appendChild(document.createTextNode(field));
          });
        list.appendChild(item);
      });
      elementToAppendTo.appendChild(list);
    }
  }

  function createDomainElement(domain) {
    if (!domain) {
      return;
    }

    var domainElement = document.createElement("span");
    domainElement.className = "lookup-definitions__definitions__domain";
    domainElement.innerText = domain;
    return domainElement;
  }

  function addDefinitionToDOM(container, item, index, selectedWord) {
    var wordTitleSelector = ".lookup-definitions__word";
    setChildInnerTextBySelector(container, wordTitleSelector, item.word);
    setTitleAnchorHref(container, wordTitleSelector, item, selectedWord);

    var labelModifierClass = "lookup-definitions__label-box--";
    var conjugation = createLabeledElement(
      labelModifierClass + "conjugation",
      item.conjugation,
    );
    if (conjugation) {
      container.appendChild(conjugation);
    }

    setChildInnerTextBySelector(
      container,
      ".lookup-definitions__partOfSpeech",
      item.partOfSpeech,
    );
    setChildInnerTextBySelector(
      container,
      ".lookup-definitions__forms",
      item.forms,
    );

    var definitionsList = document.createElement("ol");
    var singleItemClass =
      item.definitions.length === 1
        ? " lookup-definitions__definitions--single-item"
        : "";
    definitionsList.className =
      "lookup-definitions__definitions" + singleItemClass;

    item.definitions.slice(0, 3).forEach(function (definition) {
      var definitionElement = document.createElement("div");
      if (definition.domain) {
        definitionElement.appendChild(createDomainElement(definition.domain));
      }
      if (definition.definition) {
        definitionElement.appendChild(
          document.createTextNode(definition.definition),
        );
      }

      [
        createLabeledElement(labelModifierClass + "usage", definition.usage),
        createLabeledElement(
          labelModifierClass + "synonyms",
          definition.synonyms.map(createDdoLink),
        ),
        createLabeledElement(
          labelModifierClass + "relateds",
          definition.relateds.map(createDdoLink),
        ),
      ]
        .filter(Boolean)
        .forEach(function (element) {
          definitionElement.appendChild(element);
        });

      createSubDefinitionsList(definition.subDefinitions, definitionElement);

      var itemContainer = document.createElement("li");
      itemContainer.appendChild(definitionElement);
      definitionsList.appendChild(itemContainer);
    });

    container.appendChild(definitionsList);

    if (item.definitions.length > 3) {
      var word = item.word + (item.option > 1 ? "," + item.option : "");
      var link = createDdoLink(word);
      link.innerText =
        "se " +
        (item.definitions.length - 3) +
        " betydninger mere på ordnet.dk";
      container.appendChild(link);
    }
  }

  function createSeparator() {
    var separator = document.createElement("hr");
    separator.className = "lookup-definitions__separator";
    return separator;
  }

  function toggleShowModal(show) {
    var rootContainer = document.querySelector(".lookup-definitions");
    var background = document.querySelector(".lookup-definitions-background");
    if (!rootContainer || !background) {
      console.log(
        (rootContainer ? "Container" : "Background") + " element not found!",
      );
      return;
    }

    var displayProperty = show === true ? "block" : "none";
    rootContainer.style.display = displayProperty;
    background.style.display = displayProperty;
  }

  function handleWordSelection(e) {
    if (e.target.tagName.toLowerCase() === "a") {
      return;
    }

    container.parentElement.replaceChild(
      initialStateRootContainer.cloneNode(true),
      container,
    );
    container = document.querySelector(rootContainerSelector);

    var selectedWord = getSelectedWord();
    if (!selectedWord) {
      return;
    }

    document.dispatchEvent(new Event("dictionary event", { bubbles: true }));

    var definitionRequest = new XMLHttpRequest();
    definitionRequest.onreadystatechange = function () {
      if (definitionRequest.readyState !== 4) {
        return;
      }

      if (definitionRequest.status !== 200) {
        console.log("Definition not found for " + selectedWord);
        return;
      }

      var response = JSON.parse(definitionRequest.responseText);
      setChildInnerTextBySelector(
        document,
        ".lookup-definitions__hits",
        response.length,
      );
      response.forEach(function (definition, index) {
        if (index) {
          container.appendChild(createSeparator());
          initialStateRootContainer.childNodes.forEach(function (node) {
            container.appendChild(node.cloneNode(true));
          });
        }

        addDefinitionToDOM(container, definition, index, selectedWord);
      });

      toggleShowModal(true);
    };

    definitionRequest.open(
      "GET",
      "/api/definition/v1/definition/" + selectedWord,
      true,
    );
    definitionRequest.send(null);
  }

  document.querySelectorAll(".article-text").forEach(function (element) {
    element.addEventListener("dblclick", handleWordSelection);
  });

  var container = document.querySelector(rootContainerSelector);
  if (!container) {
    return;
  }

  [
    ".lookup-definitions-background",
    ".lookup-definitions__close",
    ".lookup-definitions__close--full",
  ]
    .map(function (cssClass) {
      return document.querySelector(cssClass);
    })
    .filter(Boolean)
    .forEach(function (element) {
      element.addEventListener("click", toggleShowModal);
    });
})();
